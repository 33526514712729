import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import Layout from '../components/Layout/Layout'
import Img from 'gatsby-image'

const Template = ({ data, location }) => {
  const recall = data.nodeRecalls
  return (
    <Layout location={location} nid={recall.drupal_internal__nid}>
      <section className="container mx-auto px-8">
        <div className="page-title">{recall.title}</div>
        {recall.field_recall_date &&
          <small><em>{recall.field_recall_date}</em></small>
        }
        {recall.relationships?.field_image &&
          <div className="float-right ml-3 mb-2 w-full md:w-1/3"
          >
            <Img
              fluid={
                recall.relationships.field_image.localFile.childImageSharp.fluid
              } />
          </div>
        }
        <div
          className="mt-2"
          dangerouslySetInnerHTML={{ __html: recall.body.value }}>
        </div>
        {recall?.field_website?.uri &&
          <div>
            <a href={recall.field_website.uri}
              rel="noopener noreferrer" target="_blank">
              {recall.field_website.uri}
            </a>
          </div>
        }
        {recall.field_phone &&
          <div className="mt-3">Phone: {recall.field_phone}</div>
        }
      </section>
    </Layout>
  )
}

Template.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

export default Template;

export const query = graphql`
  query($recallId: String!) {
    nodeRecalls(id: { eq: $recallId }) {
      id
      drupal_internal__nid
      title
      field_phone
      field_recall_date(formatString: "MM/DD/Y")
      field_website {
        uri
      }
      body {
        value
      }
      path {
        alias
      }
      relationships {
        field_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 650 quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }`